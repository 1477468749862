import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
// 进度提示
import 'nprogress/nprogress.css';
import NotFound from '../views/404';

Vue.use(VueRouter);

const routes = [
  {
    path: '/user',
    name: 'user',
    component: () => import('../Layout/UserLayout.vue'),
    children: [
      {
        path: '/user',
        redirect: '/user/:company/login'
      },
      {
        path: '/user/:company/login',
        name: 'login',
        component: () =>
          import(/* webpackChunkName: "user" */ '../views/User/Login')
      },
      {
        path: '/user/register',
        name: 'register',
        component: () =>
          import(/* webpackChunkName: "user" */ '../views/User/Register.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../Layout/BasicLayout'),
    children: [
      {
        path: '/',
        redirect: '/book'
      },
      {
        path: '/book',
        name: 'book',
        component: () => import('../views/Book/Book')
      },
      {
        path: '/book/:bookId',
        name: 'bookInfo',
        component: () => import('../views/Book/BookInfo')
      },
      {
        path: '/book/study/play/:bookId/:chapterId/:typeId/:unitId',
        name: 'studyPlay',
        component: () => import('../views/Study/StudyPlay')
      },
      // 话题阅读
      {
        path: '/book/study/read/:bookId/:chapterId/:typeId',
        name: 'readStudy',
        component: () => import('../views/Study/Read/index.vue')
      },
      // 单词
      {
        path:
          '/book/study/word/:wordType/:bookId/:chapterId/:typeId/:gameId/:name',
        name: 'wordStudy',
        component: () => import('../views/Study/Word/index.vue')
      },
      // 课文
      {
        path:
          '/book/study/text/:textType/:bookId/:chapterId/:typeId/:gameId/:name',
        name: 'textStudy',
        component: () => import('../views/Study/Text/index.vue')
      },
      {
        path: '/report/:type/:chapterId/:sourceId/:typeId',
        name: 'report',
        component: () => import('../views/Exam/Report')
      },
      {
        path: '/report/detail/exam/:bookId/:chapterId/:typeId/:pId',
        name: 'reportDetail',
        component: () => import('../views/Exam/ExamReportDetail')
      },
      {
        path: '/report/detail/reading/:bookId/:chapterId/:typeId/:pId',
        name: 'reportDetail',
        component: () => import('../views/Exam/ReadingReportDetail')
      },
      {
        path: '/exam/:bookId/:chapterId/:typeId/:sourceId',
        name: 'exam',
        component: () => import('../views/Exam/index')
      }
    ]
  },
  {
    path: '*',
    component: NotFound
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
