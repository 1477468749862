<template lang="html">
  <div class="loading-container" v-if="isShowLoading">
    <div class="loading-box">
      <a-spin></a-spin>
      <span class="loading-txt">{{ content || '请稍候' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowLoading: false,
      content: ''
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 1000;
}
.loading-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  background: #f2f2f2;
}
.loading-img {
  width: 70px;
  height: 70px;
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
.loading-txt {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #666;
}
</style>
