import Vue from 'vue';
import App from './App.vue';
import vueAudioNative from 'vue-audio-native';
import preview from 'vue-photo-preview';
import 'vue-photo-preview/dist/skin.css';
import router from './router';
import store from './store';
import '../public/static/iconfont/iconfont.css';
import '../public/static/global.less';
import Loading from './components/Global/Loading/index';
import Toast from './components/Global/Toast/index';
import {
  Button,
  Layout,
  Menu,
  Breadcrumb,
  Icon,
  Checkbox,
  Tabs,
  Card,
  Avatar,
  Skeleton,
  List,
  Dropdown,
  Spin,
  Radio,
  Modal,
  Empty,
  Form,
  Input,
  Select,
  Row,
  Col,
  Drawer,
  Rate,
  Slider,
  FormModel,
  message
} from 'ant-design-vue';

Vue.config.productionTip = false;
Vue.prototype.$message = message;
Vue.use(preview);

Vue.use(vueAudioNative);
Vue.use(Rate);
Vue.use(Drawer);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Select);
Vue.use(Input);
Vue.use(Form);
Vue.use(Layout);
Vue.use(Menu);
Vue.use(Breadcrumb);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(Tabs);
Vue.use(Card);
Vue.use(Avatar);
Vue.use(Skeleton);
Vue.use(List);
Vue.use(Dropdown);
Vue.use(Spin);
Vue.use(Radio);
Vue.use(Modal);
Vue.use(Loading);
Vue.use(Toast);
Vue.use(Empty);
Vue.use(Slider);
Vue.use(FormModel);
// 清除本地cookie并调用退出接口
// window.addEventListener('beforeunload', e => {
//   e = e || window.event;
//   // 兼容IE8和Firefox 4之前的版本
//   if (e) {
//     e.returnValue = '关闭提示';
//   }

//   // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
//   return '关闭提示';
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
